import * as React from "react"
import { Embed } from 'theme-ui'


export default function BlockVideoWrestling({
  subheading,
  heading,
  text,
  buttons,
  ...props
}) {
  return (
    <section py="6|8|12|20" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column|row"
          justifyContent="space-between"
          alignItems="flex-start|center"
        >
          <div flex="1">
            {subheading && (
              <p color="primary" textTransform="uppercase" m="0">
                {subheading}
              </p>
            )}
            {heading && (
              <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
                {heading}
              </h1>
            )}
            {text && (
              <p variant="text.lead" mt="2">
                {text}
              </p>
              
            )}
<div>
    <p>&nbsp;</p>
<Embed src="https://youtube.com/embed/playlist?list=PLh6vDF87aU3xUPNKP-EXBLDwyr1BcWkVh" />
<p>&nbsp;</p>
<Embed src="https://youtube.com/embed/playlist?list=PLh6vDF87aU3zQ1PWE8gZft1a_iWOFSWKC" />
</div>


</div>
          {buttons}
        </div>
        </div>        
    </section>
  )
}

